<template>
  <v-container fluid grid-list-xs fill-height class="container">
    <v-layout row wrap fluid>
      <!-- align-center -->
      <v-flex xs12 class="text-center mt-5">
        <div style="font-size: 20vw; font-weight: bold; color: black">
          C <span class="blue--text">O</span>
        </div>
        <div style="font-size: 20vw; font-weight: bold; color: red">
          D <span class="black--text">E</span>
        </div>
        <div style="font-size: 8vw; color: #b491ff">กรอกรหัสเชื่อมต่อ</div>
        <div
          v-if="isShowIncorrect"
          class="my-2"
          style="font-size: 5vw; color: deeppink"
        >
          *** คุณกรอกรหัสผิด {{ error_count }} ครั้ง ***
        </div>
        <div style="background-color: rgb(255, 248, 220, 0.6)" class="py-5">
          <PincodeInput
            v-model="code"
            placeholder="0"
            :length="5"
            v-on:input="onChange"
          />
        </div>
        <!-- <div style="font-size: 5vw; color: #b491ff">
          รหัสจะหมดอายุใน 10 วินาที
        </div>
        <div class="mt-5"><v-btn>ขอรหัสใหม่</v-btn></div> -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import PincodeInput from "vue-pincode-input";

export default {
  name: "Connector",
  components: {
    PincodeInput,
  },
  mounted() {
    // console.log(this.deviceHeight);
    //รอ 15 นาที
    // console.log('axios')
    // console.log(axios)
    this.error_count = parseInt(localStorage.getItem("error_count")) || 0;
    this.check_incorrect();
    // var today = new Date();
    // var Christmas = new Date(today-);
    // var diffMs = Christmas - today; // milliseconds between now & Christmas
    // // var diffDays = Math.floor(diffMs / 86400000); // days
    // // var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    // var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    // console.log(
    //   diffDays +
    //     " days, " +
    //     diffHrs +
    //     " hours, " +
    //     diffMins +
    //     " minutes until Christmas =)"
    // );
  },
  computed: {
    // error_count() {
    //   return localStorage.getItem("error_count");
    // },
    // deviceHeight() {
    //   return this.$vuetify.breakpoint.height;
    // },
    // deviceWidth() {
    //   return this.$vuetify.breakpoint.width;
    // },
  },
  methods: {
    onChange(v) {
      if (v.length == 5) {
        console.log(v);
        this.check_incorrect();
        if (parseInt(localStorage.getItem("error_count")) >= 5) {
          // this.isShowIncorrect = true;
          this.$alert(
            "รอ 5 นาที แล้วใส่ใหม่อีกครั้ง",
            "คุณใส่รหัสผิดเกิน 5 ครั้ง",
            "error"
          ).then(() => {});
        } else {
          this.doVerify(v);
        }
      }
    },
    async doVerify(p_code) {
      let parameter = {
        to: "login",
        method: "request_connect",
        code: p_code,
      };

      //   axios.request();
      //   console.log(this.$headers);
      await this.$store.dispatch("auth/TransferData", parameter).then(
        (response) => {
          //   if (response == "Success") {
          localStorage.removeItem("error_count");
          localStorage.removeItem("error_varify_time");

          //   this.$headers = {
          //     headers: {
          //       "Content-Type":
          //         "application/x-www-form-urlencoded; charset=UTF-8",
          //       Authorization: "Bearer " + localStorage.getItem("fun_token"),
          //     },
          //   };
          //   console.log(this.$headers);

          //   this.$axios.defaults.headers.post["Content-Type"] =
          //     "application/x-www-form-urlencoded; charset=UTF-8";

          this.$router.push("/CreatePinCode");
          //   }
          //console.log(response);
        },
        (error) => {
          switch (error) {
            case "CODE_NOT_FOUND":
              // localStorage.setItem("blog_token", data.response.token);
              this.error_count += 1;
              console.log(this.error_count);
              localStorage.setItem("error_count", this.error_count);

              var now = new Date();
              localStorage.setItem("error_varify_time", now);

              this.$alert("รหัสไม่ถูกต้อง.", "ผิดพลาด", "error").then(() => {});

              this.check_incorrect();
              break;
            default:
              alert(error);
              break;
          }
        }
      );
    },
    check_incorrect() {
      console.log(localStorage.getItem("error_count"));
      //   console.log(localStorage.getItem("error_varify_time"));

      if (this.error_count > 0) this.isShowIncorrect = true;
      else this.isShowIncorrect = false;
      console.log("isShowIncorrect");
      console.log(this.isShowIncorrect);
      if (this.error_count >= 5) {
        console.log("diff minute");
        var now = new Date();
        var diffMs = now - new Date(localStorage.getItem("error_varify_time"));
        // // var diffDays = Math.floor(diffMs / 86400000); // days
        // // var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        if (diffMins >= 5) {
          this.error_count = 0;
          localStorage.setItem("error_count", 0);
        }
        console.log(diffMins);
      }
    },
  },
  data: () => ({
    code: "",
    error_count: 0,
    isShowIncorrect: false,
  }),
};
</script>

<style scoped>
/* div.vue-pincode-input-wrapper { */
/* any styles you want for wrapper */
/* font-size: 8vw;
  color: red; */
/* margin-left: 40px; */
/* } */

::v-deep input.vue-pincode-input {
  /* any styles you want for each cell */
  /* width: 20px !important;
  padding: 4px !important;
  border: none; */
  border-bottom: 2px solid #fb91ff !important;
  color: #ee6bff !important;
  margin-right: 15px !important;
  /* font-size: 1.5rem;
  color: black;
  margin-right: 4px;
  text-align: center; */
  /* width: 20vw !important; */
  font-size: 6vw !important;
}
</style>
